import {
  Route,
  BrowserRouter,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { useHashHistory } from 'use-hash-history';

import MainLayout from 'components/MainLayout';
import NotFound from 'components/NotFound';
import PagePreloader from 'components/PagePreloader';

import Help from 'pages/Help';
import Imprint from 'pages/Imprint';
import IndexPageB from 'pages/IndexPageB';
// import IndexPageA from 'pages/IndexPage';
import Info from 'pages/Info';
import Pricing from 'pages/Pricing';
import SearchResults from 'pages/SearchResultsPage';

import { IS_EMBEDDED } from 'constants/common';
import {
  HELP_PAGE_ROUTE,
  IMPRINT_PAGE_ROUTE,
  INDEX_ROUTE,
  INFO_PAGE_ROUTE,
  PRICING_PAGE_ROUTE,
  SEARCH_RESULTS_PAGE_ROUTE,
} from 'constants/routes';

import useSetVhSizeOnMobile from 'hooks/useSetVhSizeOnMobile';
import useAuth from 'hooks/useAuth';
import useGlobalState from 'hooks/useGlobalState';
// import useABExperiment from 'hooks/useABExperiment';

const AppRouter = () => {
  useAuth({ shouldPerformAuth: true });
  // const { variant, isVariantLoading } = useABExperiment('homepageExperiment');

  const [globalState, _setGlobalState] = useGlobalState();
  const { isAuthLoading } = globalState;

  const history = useHashHistory({ hashRoot: '' });

  useSetVhSizeOnMobile();

  const routes = (
    <Routes>
      <Route element={<MainLayout />}>
        {isAuthLoading ? (
          <Route path="*" element={<PagePreloader />} />
        ) : (
          <>
            <Route path={INDEX_ROUTE} element={<IndexPageB />} />
            <Route path={SEARCH_RESULTS_PAGE_ROUTE} element={<SearchResults />} />
            <Route path={INFO_PAGE_ROUTE} element={<Info />} />
            <Route path={PRICING_PAGE_ROUTE} element={<Pricing />} />
            <Route path={HELP_PAGE_ROUTE} element={<Help />} />
            <Route path={IMPRINT_PAGE_ROUTE} element={<Imprint />} />

            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Route>
    </Routes>
  );

  return (
    <>
      {IS_EMBEDDED ? (
        <HistoryRouter history={history}>{routes}</HistoryRouter>
      ) : (
        <BrowserRouter>{routes}</BrowserRouter>
      )}
    </>
  );
};

export default AppRouter;
